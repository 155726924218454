<script setup lang="ts">
import { useAttrs } from "vue";
import {
    AntlerClasses,
    installAntlerComponent,
    responsive,
} from "@/Utils/component";
export type ButtonGroupAs = "div" | "section";
export type ButtonGroupVariant = "spaced" | "column" | "nowrap" | "background";
export type ButtonGroupGap = "small" | "regular" | "medium" | "none";

type Props = {
    as?: ButtonGroupAs;
    variant?: ButtonGroupVariant | ButtonGroupVariant[];
    gap?: ButtonGroupGap;
};

const {
    as = "div",
    variant = undefined,
    gap = "regular",
} = defineProps<Props>();

const attrs = useAttrs();

// classes
const classes: AntlerClasses<Props> = {
    base: "flex items-center flex-wrap",
    variants: {
        variant: {
            spaced: responsive("justify-between w-full"),
            column: responsive("flex-col"),
            nowrap: responsive("flex-nowrap overflow-auto justify-start"),
            background: "bg-slate-100 p-4 rounded",
        },
        gap: {
            small: "gap-2",
            regular: "gap-3",
            none: "gap-0",
            medium: "gap-4",
        },
    },
};
const { aClass } = installAntlerComponent(
    "button-group",
    { variant, gap },
    classes,
    attrs,
);
</script>

<template>
    <component
        :is="as"
        :class="aClass()"
    >
        <slot />
    </component>
</template>
